<template>
  <AppLayout>
    <div @click="closeDropDown"
         v-loading.fullscreen.lock="fullscreenLoading"
    >
      <div class="goodscompare" v-if="popUpList == 1">
        <div class="goodscompare-1">哪一款更合心意?</div>
        <div :class="show1 == 0 ? 'goodscompare-2' : 'goodscompare-2-plus'">
          <div class="goodscompare-2-1" @click.stop="showgood1">
            <div class="goodscompare-2-2">{{billInfo.productName}}</div>
            <img
              class="goodscompare-2-3"
              src="./image/down.png"
              v-if="show1 == 0"
            />
            <img
              class="goodscompare-2-3"
              src="./image/toward.png"
              v-if="show1 == 1"
            />
          </div>
          <div v-if="show1 == 0">
            <img :src="billInfo.picture" class="goodscompare-2-4" />
          </div>
          <div class="goodscompare-2-5" v-if="show1 == 0">
            <el-select v-model="select1" placeholder="请选择" @change="goodMaterial1">
              <el-option
                v-for="item in goddsOption1"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
          <div class="goodscompare-2-6" v-if="show1 == 0" @click="toDetailGoods(billInfo.id)">￥{{material1.configPrice}}</div>
          <div class="goodscompare-2-6" v-if="show1 == 1">
            <div class="goodscompare-2-7" v-for="(item,index) in goddsInfo" @click="checkgoods1(item)">
              <img :src="item.picture" class="goodscompare-2-8" />
              <div class="goodscompare-2-9">{{item.productName}}</div>
              <div class="goodscompare-2-10" v-if="item.isMe">已选</div>
            </div>
          </div>
        </div>
        <div class="goodscompareall">
          <div class="goodscompare-2-7">全部参数</div>
          <div class="goodscompare-2-8"></div>
	        <img v-if="isClick" src="./image/check.png" class="goodscompare-2-9" @click="showDateDistinction"/>
	        <img v-else src="./image/cicrl.png" class="goodscompare-2-9" @click="showDateDistinction"/>
	        <div class="goodscompare-2-10">仅看参数差异</div>
        </div>
        <div :class="show2 == 0 ? 'goodscompare-3' : 'goodscompare-3-plus'">
          <div class="goodscompare-2-1" @click.stop="showgood2">
            <div class="goodscompare-2-2" style="color: #666666;" v-if="productName1 == ''">请选择对比商品</div>
            <div class="goodscompare-2-2" v-else>{{productName1}}</div>
            <img
              class="goodscompare-2-3"
              src="./image/down.png"
              v-if="show2 == 0"
            />
            <img
              class="goodscompare-2-3"
              src="./image/toward.png"
              v-if="show2 == 1"
            />
          </div>
          <div class="goodscompare-2-4" v-if="show2 == 0&&productName1 == ''" @click.stop="showgood2">
            <img src="./image/plus.png" class="goodscompare-2-4-1" />
          </div>
          <div class="goodscompare-2-5" v-if="show2 == 0&&productName1 == ''">请选择对比商品</div>
          <div class="goodscompare-2-6" v-if="show2 == 1">
            <div class="goodscompare-2-7" v-for="(item,index) in goddsInfo" @click="checkgoods2(item)">
              <img :src="item.picture" class="goodscompare-2-8" />
              <div class="goodscompare-2-9">{{item.productName}}</div>
              <div class="goodscompare-2-10" v-if="item.isMe">已选</div>
            </div>
          </div>
          <div v-if="show2 == 0&&productName1 != ''">
            <img :src="billInfo1.picture" class="goodscompare-2-4-plus" />
          </div>
          <div class="goodscompare-2-5-plus" v-if="show2 == 0&&productName1 != ''">
            <el-select v-model="select2" placeholder="请选择" @change="goodMaterial2">
              <el-option
                v-for="item in billInfo1.configTypes"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
          <div class="goodscompare-2-6-plus" v-if="show2 == 0&&productName1 != ''" @click="toDetailGoods(billInfo1.id)">￥{{material2.configPrice}}</div>
        </div>
        <div :class="show3 == 0 ? 'goodscompare-4' : 'goodscompare-4-plus'">
          <div class="goodscompare-2-1" @click.stop="showgood3">
            <div class="goodscompare-2-2" style="color: #666666;" v-if="productName2 == ''">请选择对比商品</div>
            <div class="goodscompare-2-2" v-else>{{productName2}}</div>
            <img
              class="goodscompare-2-3"
              src="./image/down.png"
              v-if="show3 == 0"
            />
            <img
              class="goodscompare-2-3"
              src="./image/toward.png"
              v-if="show3 == 1"
            />
          </div>
          <div class="goodscompare-2-4" v-if="show3 == 0&&productName2 == ''" @click.stop="showgood3">
            <img src="./image/plus.png" class="goodscompare-2-4-1" />
          </div>
          <div class="goodscompare-2-5" v-if="show3 == 0&&productName2 == ''">请选择对比商品</div>
          <div class="goodscompare-2-6" v-if="show3 == 1">
            <div class="goodscompare-2-7" v-for="(item,index) in goddsInfo" @click="checkgoods3(item)">
              <img :src="item.picture" class="goodscompare-2-8" />
              <div class="goodscompare-2-9">{{item.productName}}</div>
              <div class="goodscompare-2-10" v-if="item.isMe">已选</div>
            </div>
          </div>
          <div v-if="show3 == 0&&productName2 != ''">
            <img :src="billInfo2.picture" class="goodscompare-2-4-plus" />
          </div>
          <div class="goodscompare-2-5-plus" v-if="show3 == 0&&productName2 != ''">
            <el-select v-model="select3" placeholder="请选择" @change="goodMaterial3">
              <el-option
                v-for="item in billInfo2.configTypes"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
          <div class="goodscompare-2-6-plus" v-if="show3 == 0&&productName2 != ''" @click="toDetailGoods(billInfo2.id)">￥{{material3.configPrice}}</div>
        </div>
      </div>
      <div class="goodscompare2" v-if="popUpList == 2">
        <div>
          <div class="goodscompare2-1" @click.stop="showgood1">
            <div :class="show1 == 0 ? 'goodscompare2-2' : 'goodscompare2-2-plus'">{{billInfo.productName}}</div>
            <img class="goodscompare2-3" src="./image/down.png"  v-if="show1 == 0"/>
            <img class="goodscompare2-3" src="./image/toward.png" v-if="show1 == 1" />
          </div>
          <img v-if="show1 == 0" :src="billInfo.picture" class="goodscompare2-4" />
          <div class="goodscompare2-5" v-if="show1 == 0">{{billInfo.productName}}</div>
          <div class="goodscompare2-6" v-if="show1 == 0" @click="toDetailGoods(billInfo.id)">￥{{material1.configPrice}}</div>
          <div class="goodscompare2-7">全部参数</div>
          <div class="goodscompare2-8"></div>
	        <img v-if="isClick" src="./image/check.png" class="goodscompare2-9" @click="showDateDistinction"/>
	        <img v-else src="./image/cicrl.png" class="goodscompare2-9" @click="showDateDistinction"/>
          <div class="goodscompare2-10">仅看参数差异</div>
        </div>
        <div class="goodscompare2-else" v-if="show1 == 1">
          <div class="goodscompare2-1" @click.stop="showgood1">
            <div class="goodscompare2-2">{{billInfo.productName}}</div>
            <img class="goodscompare2-3" src="./image/toward.png" />
          </div>
          <div class="goodscompare-2-1">
            <div class="goodscompare-2-2" v-for="(item,index) in goddsInfo" @click="checkgoods1(item)">
              <img :src="item.picture" class="goodscompare-2-3" />
              <div class="goodscompare-2-4">{{item.productName}}</div>
              <div class="goodscompare-2-10" v-if="item.isMe">已选</div>
            </div>
          </div>
        </div>
        <div>
          <div class="goodscompare2-11" @click.stop="showgood2">
            <div class="goodscompare2-12" style="color: #666666;" v-if="productName1 == ''">请选择对比商品</div>
            <div class="goodscompare2-12" v-else>{{productName1}}</div>
            <img class="goodscompare2-13" src="./image/down.png" v-if="show2 == 0" />
            <img class="goodscompare2-13" src="./image/toward.png" v-if="show2 == 1" />
          </div>
          <img :src="billInfo1.picture" v-if="show2 == 0&&productName1 != ''" class="goodscompare2-14" />
          <div class="goodscompare2-15" v-if="show2 == 0&&productName1 != ''">{{productName1}}</div>
          <div class="goodscompare2-16" v-if="show2 == 0&&productName1 != ''" @click="toDetailGoods(billInfo1.id)">￥{{material2.configPrice}}</div>
        </div>
        <div class="goodscompare2-else1" v-if="show2 == 1">
          <div class="goodscompare2-1" @click.stop="showgood2">
            <div class="goodscompare2-2" style="color: #666666;" v-if="productName1 == ''">请选择对比商品</div>
            <div class="goodscompare2-2" v-else>{{productName1}}</div>
            <img class="goodscompare2-3" src="./image/down.png" />
          </div>
          <div class="goodscompare-2-1">
            <div class="goodscompare-2-2" v-for="(item,index) in goddsInfo" @click="checkgoods2(item)">
              <img :src="item.picture" class="goodscompare-2-3" />
              <div class="goodscompare-2-4">{{item.productName}}</div>
              <div class="goodscompare-2-10" v-if="item.isMe">已选</div>
            </div>
          </div>
        </div>
        <div>
          <div class="goodscompare2-17" @click.stop="showgood3">
            <div class="goodscompare2-18" style="color: #666666;" v-if="productName2 == ''">请选择对比商品</div>
            <div class="goodscompare2-18" v-else>{{productName2}}</div>
            <img class="goodscompare2-19" src="./image/down.png" v-if="show3 == 0" />
            <img class="goodscompare2-19" src="./image/toward.png" v-if="show3 == 1" />
          </div>
          <img :src="billInfo2.picture" v-if="show3 == 0&&productName2 != ''" class="goodscompare2-20" />
          <div class="goodscompare2-21" v-if="show3 == 0&&productName2 != ''">{{productName2}}</div>
          <div class="goodscompare2-22" v-if="show3 == 0&&productName2 != ''" @click="toDetailGoods(billInfo2.id)">￥{{material3.configPrice}}</div>
        </div>
        <div class="goodscompare2-else2" v-if="show3 == 1">
          <div class="goodscompare2-1" @click.stop="showgood3">
            <div class="goodscompare2-2" style="color: #666666;" v-if="productName2 == ''">请选择对比商品</div>
            <div class="goodscompare2-2" v-else>{{productName2}}</div>
            <img class="goodscompare2-3" src="./image/down.png" />
          </div>
          <div class="goodscompare-2-1">
            <div class="goodscompare-2-2" v-for="(item,index) in goddsInfo" @click="checkgoods3(item)">
              <img :src="item.picture" class="goodscompare-2-3" />
              <div class="goodscompare-2-4">{{item.productName}}</div>
              <div class="goodscompare-2-10" v-if="item.isMe">已选</div>
            </div>
          </div>
        </div>
      </div>
	    <div class="compare">
		    <div class="compare1" v-for="(type,index) in materialType">
			    <div class="compare-1">
				    <div class="container-top-left">
					    <div class="title-text">
						    <span class="text-span"></span>
						    <div class="container-top-l">{{materialTypeName(type)}}</div>
					    </div>
					    <div class="container-top-2" v-for="(item,index) in material1[type]">
						    <div class="container-title">
							    <div class="container-top-3">{{item.typeName}}</div>
						    </div>
						    <div class="container-content">
							    <div class="container-top-4">
								    {{item.materialName == undefined ? '—' : item.materialName}}
								    <!--{{item.materialName}}-->
							    </div>
							    <div class="container-top-5">
								    {{item.amount == 0 ? '&nbsp' : `x${item.amount}`}}
								    <!--x{{item.amount}}-->
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
			    <div class="compare-2">
				    <div v-show="Object.keys(material2).length !== 0">
					    <div class="container-top-left">
						    <div class="title-text">
							    <!--<span class="text-span"></span>
							    <div class="container-top-l">{{materialTypeName(type)}}</div>-->
						    </div>
						    <div class="container-top-2" v-for="(item,index) in material2[type]">
							    <div class="container-title">
								    <div class="container-top-3">{{item.typeName}}</div>
							    </div>
							    <div class="container-content">
								    <div :class=" item.isDiffer ? 'container-top-4-plus' : 'container-top-4'">
									    {{item.materialName == undefined ? '—' : item.materialName}}
									    <!--{{item.materialName}}-->
								    </div>
								    <div :class=" item.isDiffer ? 'container-top-5-plus' : 'container-top-5'">
									    {{item.amount == 0 ? '&nbsp' : `x${item.amount}`}}
								    </div>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
			    <div class="compare-3">
				    <div v-show="Object.keys(material3).length !== 0">
					    <div class="container-top-left">
						    <div class="title-text">
							    <!--<span class="text-span"></span>
							    <div class="container-top-l">{{materialTypeName(type)}}</div>-->
						    </div>
						    <div class="container-top-2" v-for="(item,index) in material3[type]">
							    <div class="container-title">
								    <div class="container-top-3">{{item.typeName}}</div>
							    </div>
							    <div class="container-content">
								    <div :class=" item.isDiffer ? 'container-top-4-plus' : 'container-top-4'">
									    {{item.materialName == undefined ? '—' : item.materialName}}
									    <!--{{item.materialName}}-->
								    </div>
								    <div :class=" item.isDiffer ? 'container-top-5-plus' : 'container-top-5'">
									    {{item.amount == 0 ? '&nbsp' : `x${item.amount}`}}
								    </div>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
		    </div>
		    <!--赠品-->
		    <div class="compare1">
			    <div class="compare-1">
				    <div class="container-top-left">
					    <div class="title-text">
						    <span class="text-span"></span>
						    <div class="container-top-l">赠品</div>
					    </div>
					    <div class="container-top-2" v-for="(item,index) in materialGift1">
						    <div class="container-title">
							    <div class="container-top-3">{{item.count > 0 ? `赠品${index+1}` : '&nbsp'}}</div>
						    </div>
						    <div class="container-content">
							    <div class="container-top-4">
								    {{item.count > 0 ? item.giftName : '&nbsp'}}
							    </div>
							    <div class="container-top-5">{{item.count > 0 ? `x${item.count}` : '&nbsp'}}</div>
						    </div>
					    </div>
				    </div>
			    </div>
			    <div class="compare-2">
				    <div v-show="Object.keys(material2).length !== 0">
					    <div class="container-top-left">
						    <div class="title-text">
							    <!--<span class="text-span"></span>
							    <div class="container-top-l">赠品</div>-->
						    </div>
						    <div class="container-top-2" v-for="(item,index) in materialGift2">
							    <div class="container-title">
								    <div class="container-top-3">{{item.count > 0 ? `赠品${index+1}` : '&nbsp'}}</div>
							    </div>
							    <div class="container-content">
								    <div :class=" item.isDiffer ? 'container-top-4-plus' : 'container-top-4'">
									    {{item.count > 0 ? item.giftName : '&nbsp'}}
								    </div>
								    <div :class=" item.isDiffer ? 'container-top-5-plus' : 'container-top-5'">
									    {{item.count > 0 ? `x${item.count}` : '&nbsp'}}
								    </div>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
			    <div class="compare-3">
				    <div v-show="Object.keys(material3).length !== 0">
					    <div class="container-top-left">
						    <div class="title-text">
							    <!--<span class="text-span"></span>
							    <div class="container-top-l">赠品</div>-->
						    </div>
						    <div class="container-top-2" v-for="(item,index) in materialGift3">
							    <div class="container-title">
								    <div class="container-top-3">{{item.count > 0 ? `赠品${index+1}` : '&nbsp'}}</div>
							    </div>
							    <div class="container-content">
								    <div :class=" item.isDiffer ? 'container-top-4-plus' : 'container-top-4'">
									    {{item.count > 0 ? item.giftName : '&nbsp'}}
								    </div>
								    <div :class=" item.isDiffer ? 'container-top-5-plus' : 'container-top-5'">
									    {{item.count > 0 ? `x${item.count}` : '&nbsp'}}
								    </div>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
		    </div>
    </div>
  </div>
  </AppLayout>
  <HomeBackup />
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import AppLayout from "@/components/AppLayout";
import {
	comparelist,
	compare,
	compareconfig,
	checkconfig, onlyDiffer, listCompare, generateTraceid
} from "@/api/details";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import useScrollTop from "@/hooks/useScrollTops";
import HomeBackup from "@/views/home/components/HomeBackup.vue";
const route = useRoute();
const goddsInfo = ref([])
const goddsOption1 = ref([])
const select1 = ref('')
const traceId = ref('')
const productId2 = ref("");
let isClick = ref(false)
const closeDropDown = () => {
	if(show1.value == 1 || show2.value == 1 || show3.value == 1) {
		show1.value = 0;
		show2.value = 0;
		show3.value = 0;
	}
}
// 筛选物料差异
const showDateDistinction = () => {
	isClick.value = !isClick.value;
	fullscreenLoading.value = true;
	if(isClick.value) {
		/* 前端比较差异
		const codesToRemove = new Set()
		const categories = ['component', 'radiator', 'service', 'softWare', 'store', 'sysCore'];
		if(Object.keys(material2.value).length !== 0){
			if(Object.keys(material3.value).length !== 0){
				categories.forEach((category)=>{
					const list1 = material1.value[category]
					const list2 = material2.value[category]
					const list3 = material3.value[category]
					list2.forEach(item2 => {
						if (!item2.isDiffer) {
							list3.forEach(item3 => {
								if (item2.materialCode === item3.materialCode && !item3.isDiffer) {
									codesToRemove.add(item2.materialCode);
								}
							});
						}
					});
					material1.value[category] = list1.filter(item => !codesToRemove.has(item.materialCode));
					material2.value[category] = list2.filter(item => !codesToRemove.has(item.materialCode));
					material3.value[category] = list3.filter(item => !codesToRemove.has(item.materialCode));
				})
			}else{
				categories.forEach((category)=>{
					const list1 = material1.value[category]
					const list2 = material2.value[category]
					list2.forEach(item2 => {
						if (!item2.isDiffer) {
							codesToRemove.add(item2.materialCode);
						}
					});
					material1.value[category] = list1.filter(item => !codesToRemove.has(item.materialCode));
					material2.value[category] = list2.filter(item => !codesToRemove.has(item.materialCode));
				})
			}
		}else if(Object.keys(material3.value).length !== 0){
			categories.forEach((category)=>{
				const list1 = material1.value[category]
				const list3 = material3.value[category]
				list3.forEach(item3 => {
					if (!item3.isDiffer) {
						codesToRemove.add(item3.materialCode);
					}
				});
				material1.value[category] = list1.filter(item => !codesToRemove.has(item.materialCode));
				material3.value[category] = list3.filter(item => !codesToRemove.has(item.materialCode));
			})
		}else{
			return
		}*/
		// 后端比较
		let data = [{
			configId: select1.value,
			id: productId.value,
		}];
		if(Object.keys(material2.value).length !== 0){
			if(Object.keys(material3.value).length !== 0){
				data.push({
					configId: select2.value,
					id: productId1.value,
				},{
					configId: select3.value,
					id: productId2.value,
				});
				alignedMaterial1Copy.value = structuredClone(material1.value); // 反选仅看参数差异按钮使用
				alignedMaterial2Copy.value = structuredClone(material2.value); // 反选仅看参数差异按钮使用
				alignedMaterial3Copy.value = structuredClone(material3.value); // 反选仅看参数差异按钮使用
				onlyDiffer(data).then((res) => {
					material1.value = res.data[0].productConfig;
					material1.value.configPrice = initMaterial1.value.configPrice;
					materialGift1.value = res.data[0].gits;
					material2.value = res.data[1].productConfig;
					material2.value.configPrice = initMaterial2.value.configPrice;
					materialGift2.value = res.data[1].gits;
					material3.value = res.data[2].productConfig;
					material3.value.configPrice = initMaterial3.value.configPrice;
					materialGift3.value = res.data[2].gits;
				}).then(()=>{
					// 比较第一个和第二个商品对齐
					const initMaterial1Temp = structuredClone(initMaterial1.value);
					initMaterial1.value = structuredClone(material1.value);
					const material13Temp = structuredClone(material3.value);
					material3.value = {};
					diffMaterial(material2.value);

					// 比较第一个和第三个商品对齐
					initMaterial1.value = structuredClone(initMaterial1Temp)
					material3.value = structuredClone(material13Temp)
					alignedMaterial2.value = structuredClone(material2.value)
					diffMaterial(material3.value,3);
				})
			}else{
				data.push({
          configId: select2.value,
          id: productId1.value,
        });
				alignedMaterial1Copy.value = structuredClone(material1.value); // 反选仅看参数差异按钮使用
				alignedMaterial2Copy.value = structuredClone(material2.value); // 反选仅看参数差异按钮使用
				onlyDiffer(data).then((res) => {
					material1.value = res.data[0].productConfig;
					material1.value.configPrice = initMaterial1.value.configPrice;

					materialGift1.value = res.data[0].gits;
					material2.value = res.data[1].productConfig;
					material2.value.configPrice = initMaterial2.value.configPrice;
					materialGift2.value = res.data[1].gits;
				}).then(()=>{
					const initMaterial1Temp = structuredClone(initMaterial1.value);
					initMaterial1.value = structuredClone(material1.value);
					diffMaterial(material2.value)
					initMaterial1.value = structuredClone(initMaterial1Temp)
				})
			}
		}else if(Object.keys(material3.value).length !== 0){
			data.push({
        configId: select3.value,
        id: productId2.value,
      });
			alignedMaterial1.value = material1.value; // 反选仅看参数差异按钮使用
			alignedMaterial3.value = material3.value; // 反选仅看参数差异按钮使用
			onlyDiffer(data).then((res) => {
				material1.value = res.data[0].productConfig;
				material1.value.configPrice = initMaterial1.value.configPrice;
				initMaterial1.value = structuredClone(material1.value);
				materialGift1.value = res.data[0].gits;
				material3.value = res.data[1].productConfig;
				material3.value.configPrice = initMaterial3.value.configPrice;
				materialGift3.value = res.data[1].gits;
			}).then(()=>{
				const initMaterial1Temp = structuredClone(initMaterial1.value);
				initMaterial1.value = structuredClone(material1.value);
				diffMaterial(material3.value)
				initMaterial1.value = structuredClone(initMaterial1Temp)
			})
		}else{
			alignedMaterial1Copy.value = material1.value; // 反选仅看参数差异按钮使用
		}
	}else{
		material1.value = structuredClone(alignedMaterial1Copy.value)
		material2.value = structuredClone(alignedMaterial2Copy.value);
		material3.value = structuredClone(alignedMaterial3Copy.value);
	}
	fullscreenLoading.value = false;
}
const materialType = ['sysCore','store', 'radiator', 'component', 'softWare', 'service']
const materialTypeName = (type => {
	switch (type) {
    case'sysCore':
      return '系统核心';
    case'store':
      return '存储';
    case 'radiator':
      return '散热';
    case 'component':
      return '配件';
    case'softWare':
      return '软件';
    case'service':
      return '服务';
    default:
      return '';
  }
})
const showgood1 = () => {
  if( show1.value == 0){
    show1.value = 1;
    goodsList()
  }else{
    show1.value = 0;
  }
};

const goodsList = () => {
	listCompare(productId.value,traceId.value).then((res) => {
    goddsInfo.value = res.data
  })
}
const goddsOption2 = ref([])
const show2 = ref(0);
const show1 = ref(0);
const sellNum = ref("");
const select3 = ref("");
const productId = ref("");
const productName1 = ref('');
const productName2 = ref('');
const material1 = ref({});
const materialGift1 = ref([]); // 赠品
const initMaterial1 = ref({}); // 原始物料数据
const alignedMaterial1 = ref({}); // 对齐后的物料
const alignedMaterial1Copy = ref({}); // 反选仅看参数差异数据
const billInfo = ref({});
const goddsOption3 = ref([])
const tapPane = ref([]);
const material2 = ref({});
const materialGift2 = ref([]); // 赠品
const initMaterial2 = ref({}); // 原始物料数据
const alignedMaterial2 = ref({}); // 对齐后的物料
const alignedMaterial2Copy = ref({}); // 反选仅看参数差异数据
const material3 = ref({});
const materialGift3 = ref([]); // 赠品
const initMaterial3 = ref({}); // 原始物料数据
const alignedMaterial3 = ref({}); // 对齐后的物料
const alignedMaterial3Copy = ref({}); // 反选仅看参数差异数据
const showgood3 = () => {
  if( show3.value == 0){
    show3.value = 1;
    goodsList()
  }else{
    show3.value = 0;
  }
};
const show3 = ref(0);
const billInfo1 = ref({});
const billInfo2 = ref({});
const productId1 = ref("");
const router = useRouter();
const showgood2 = () => {
  if( show2.value == 0){
    show2.value = 1;
    goodsList()
  }else{
    show2.value = 0;
  }
};
let fullscreenLoading = ref(false)
let deleteGoodsId = ref(""); // 要删除商品的id
const checkgoods1 = async (val) => {
	// 修改商品1
	fullscreenLoading.value = true;
  const res = await compareconfig(val.id,val.configTypes[0].key,billInfo.value.id,traceId.value)
  billInfo.value = res.data
  goddsOption1.value = res.data.configTypes
  material1.value = res.data.productConfig
  materialGift1.value = res.data.gifts
  initMaterial1.value = structuredClone(material1.value)
  alignedMaterial1.value = structuredClone(material1.value)
  select1.value = res.data.configTypes[0].key
  productId.value = res.data.id
  show1.value = 0

  isClick.value = false;
  // 重新对比商品
  if(select2.value && select3.value){
	  material3.value={};
	  await goodMaterial2(select2.value);
	  await goodMaterial3(select3.value,3);
  }else{
		if (select2.value) {
		  await goodMaterial2(select2.value)
	  }
		if (select3.value) {
		  await goodMaterial3(select3.value)
	  }
  }
	fullscreenLoading.value = false
}
const goodMaterial1 = async (val) => {
	// 修改商品1配置
	fullscreenLoading.value = true;
  const res = await checkconfig(productId.value,val)
  material1.value = res.data
  initMaterial1.value = structuredClone(material1.value)
  select1.value = res.data.configId
  show1.value = 0

  isClick.value = false;
  // 重新对比商品
  if(select2.value && select3.value){
	  material3.value={};
	  await goodMaterial2(select2.value);
	  await goodMaterial3(select3.value,3);
  }else{
	  if (select2.value) {
		  await goodMaterial2(select2.value)
	  }
	  if (select3.value) {
		  await goodMaterial3(select3.value)
	  }
  }
	fullscreenLoading.value = false;
}
const popUpList = ref(1);
const value = ref("");
const checkgoods2 = async (val) => {
	// 修改商品2
	if(billInfo1.value.id != ""){
		deleteGoodsId.value = billInfo1.value.id
	}else{
		deleteGoodsId.value = ""
	}
	fullscreenLoading.value = true;
	productId1.value = val.id;
  let data = [{
    configId: select1.value,
    id: productId.value,
    traceId: traceId.value,
	  productId: deleteGoodsId.value, // 要删除已选的商品id
  },{
    configId: val.configTypes[0].key,
    id: val.id,
    traceId: traceId.value,
  }];
	isClick.value = false;
  const res = await comparelist(data)
  billInfo1.value = res.data
  material2.value = res.data.productConfig
  materialGift2.value = res.data.gifts
  initMaterial2.value = structuredClone(material2.value)
  alignedMaterial2.value = structuredClone(material2.value)
  productName1.value = res.data.productName
  select2.value = res.data.configTypes[0].key
  productId1.value = res.data.id
  show2.value = 0

	// 重新对比商品
	if(select3.value){
		material2.value = {};
		select2.value = "";
		await goodMaterial3(select3.value);
		material2.value=structuredClone(initMaterial2.value);
		select2.value = res.data.configTypes[0].key;
	}
  diffMaterial(material2.value,2);
	fullscreenLoading.value = false;
}
const goodMaterial2 = async (val) => {
	// 修改商品2配置
  /*checkconfig(productId1.value,val).then((res) => {
    material2.value = res.data
	  initMaterial2.value = structuredClone(material2.value)
    select2.vaue = res.data.configId
    show2.value = 0
  })*/
	fullscreenLoading.value = true;
	let data = [{
		configId: select1.value,
		id: productId.value,
		traceId: traceId.value
	},{
		configId: val,
		id: productId1.value,
		traceId: traceId.value
	}];
	isClick.value = false;
	const res = await comparelist(data)
	material2.value = res.data.productConfig
	initMaterial2.value = structuredClone(material2.value)
	select2.value = val
	show2.value = 0

	// 重新对比商品
	if(select3.value){
		material2.value = {};
		select2.value = "";
		await goodMaterial3(select3.value);
		material2.value=structuredClone(initMaterial2.value);
		select2.value = val;
	}
	diffMaterial(material2.value,2);
	fullscreenLoading.value = false;
}
const checkgoods3 = async (val) => {
	// 修改商品3
	if(billInfo2.value.id != ""){
		deleteGoodsId.value = billInfo2.value.id
	}else{
		deleteGoodsId.value = ""
	}
	fullscreenLoading.value = true;
	productId2.value = val.id;
  let data = [{
    configId: select1.value,
    id: productId.value,
    traceId: traceId.value,
	  productId: deleteGoodsId.value, // 要删除已选的商品id
  },{
    configId: val.configTypes[0].key,
    id: val.id,
    traceId: traceId.value,
  }];
	isClick.value = false;
  const res = await comparelist(data)
  billInfo2.value = res.data
  material3.value = res.data.productConfig
  materialGift3.value = res.data.gifts
  initMaterial3.value = structuredClone(material3.value)
  alignedMaterial3.value = structuredClone(material3.value)
  productName2.value = res.data.productName
  select3.value = res.data.configTypes[0].key
  productId2.value = res.data.id
  show3.value = 0

	// 重新对比商品
  if(select2.value){
	  material3.value = {};
	  select3.value = "";
	  await goodMaterial2(select2.value);
	  material3.value=structuredClone(initMaterial3.value);
	  select3.value = res.data.configTypes[0].key;
  }
  diffMaterial(material3.value,3);
	fullscreenLoading.value = false;
}
const scrollTop = useScrollTop();
const goodMaterial3 = async (val) => {
	// 修改商品3配置
  /*checkconfig(productId2.value,val).then((res) => {
    material3.value = res.data
	  initMaterial3.value = structuredClone(material3.value)
    select3.vaue = res.data.configId
    show3.value = 0
  })*/
	fullscreenLoading.value = true;
	let data = [{
		configId: select1.value,
		id: productId.value,
		traceId: traceId.value
	},{
		configId: val,
		id: productId2.value,
		traceId: traceId.value
	}];
	isClick.value = false;
	const res = await comparelist(data)
	material3.value = res.data.productConfig
	initMaterial3.value = structuredClone(material3.value)
	select3.value = val
	show3.value = 0

	// 重新对比商品
	if(select2.value){
		material3.value = {};
		select3.value = "";
		await goodMaterial2(select2.value);
		material3.value=structuredClone(initMaterial3.value);
		select3.value = val;
	}
	diffMaterial(material3.value,3);
	fullscreenLoading.value = false;
}
window.addEventListener("scroll", function () {
  var scrollHeight = window.scrollY || document.documentElement.scrollTop;
  if (scrollHeight > 0) {
    popUpList.value = 2;
  } else {
    popUpList.value = 1;
  }
});
const getTraceid = async () => {
	const res = await generateTraceid();
	traceId.value = res.data;
}
const goodsCompare = () => {
  compareconfig(route.query.productId,route.query.configId,deleteGoodsId.value,traceId.value).then((res) => {
    billInfo.value = res.data
    goddsOption1.value = res.data.configTypes
    material1.value = res.data.productConfig
	  initMaterial1.value = structuredClone(material1.value)
    select1.value = route.query.configId
    productId.value = res.data.id
  })
};
const toDetailGoods = (productId) => {
	/*router.push({
		path: `/detailsGoods/${productId}/${1}`
	})*/
	router.push({
		name: "detailsGoods",
		query: {
			id: productId,
			houseNo: 1
		}
	});
}
const select2 = ref()
const options = ref([
  {
    value: "选项1",
    label: "黄金糕",
  },
  {
    value: "选项2",
    label: "双皮奶",
  },
]);
// 对比商品物料，保持每一行物料对齐
const diffMaterial = (contrastMaterial,num) => {
	// 已选2个商品
	if(Object.keys(material2.value).length == 0 || Object.keys(material3.value).length == 0) {
		materialType.forEach((category) => {
			let list1 = structuredClone(initMaterial1.value[category])
			let list2 = contrastMaterial[category]
				list2.forEach((item2, index) => {
					// list1和list2物料数量相等，物料种类不同
					if(index + 1 <= list1.length) {
						if (item2.materialName !== undefined && item2.isDiffer == true && list1[index].typeName != item2.typeName) {
							list1.splice(index, 0, {
								amount: 0,
								isDiffer: false,
								type: item2.type,
								typeName: item2.typeName,
							})
						}
					}else{
						// list2数量比list1多
						list1.push({
              amount: 0,
              isDiffer: false,
              type: item2.type,
              typeName: item2.typeName,
            })
					}
				});
			material1.value[category] = list1;
			alignedMaterial1.value[category] = list1;
		})
	}else if(num == 2){
		// 已选3个商品，且修改第二个商品
		materialType.forEach((category) => {
			let list1 = alignedMaterial1.value[category]
			let list2 = contrastMaterial[category]
			let list3 = material3.value[category]
			// 商品2比商品1物料种类多
			if (list2.length >= list1.length){
				list2.forEach((item2, index) => {
					if(index + 1 <= list1.length){
						if (item2.typeName != list1[index].typeName) {
							list1.splice(index, 0, {
								amount: 0,
								isDiffer: false,
								type: item2.type,
								typeName: item2.typeName,
							})
							list3.splice(index, 0, {
								amount: 0,
								isDiffer: false,
								type: item2.type,
								typeName: item2.typeName,
							})
						}
					}else{
						list1.push({
							amount: 0,
							isDiffer: false,
							type: item2.type,
							typeName: item2.typeName,
						})
						list3.push({
							amount: 0,
							isDiffer: false,
							type: item2.type,
							typeName: item2.typeName,
						})
					}
				})
				material1.value[category] = list1;
				alignedMaterial1.value[category] = list1;
				material3.value[category] = list3;
				alignedMaterial3.value[category] = list3;
			}else{
				// 商品2比商品1物料种类少
				list1.forEach((item1, index) => {
					if(index + 1 <= list2.length){
						if (item1.typeName != list2[index].typeName) {
							list2.splice(index, 0, {
								amount: 0,
								isDiffer: false,
								type: item1.type,
								typeName: item1.typeName,
							})
						}
					}else{
						list2.push({
							amount: 0,
							isDiffer: false,
							type: item1.type,
							typeName: item1.typeName,
						})
					}
				})
				material2.value[category] = list2;
				alignedMaterial2.value[category] = list2;
			}
		})
	}else if(num==3){
		// 已选3个商品，且修改第三个商品
		materialType.forEach((category) => {
			let list1 = alignedMaterial1.value[category]
			let list2 = material2.value[category]
			let list3 = contrastMaterial[category]
			// 商品3比商品1物料种类多
			if (list3.length >= list1.length){
				list3.forEach((item3, index) => {
					if(index + 1 <= list1.length){
						if (item3.typeName != list1[index].typeName) {
							list1.splice(index, 0, {
								amount: 0,
								isDiffer: false,
								type: item3.type,
								typeName: item3.typeName,
							})
							list2.splice(index, 0, {
								amount: 0,
								isDiffer: false,
								type: item3.type,
								typeName: item3.typeName,
							})
						}
					}else{
						list1.push({
							amount: 0,
							isDiffer: false,
							type: item3.type,
							typeName: item3.typeName,
						})
						list2.push({
							amount: 0,
							isDiffer: false,
							type: item3.type,
							typeName: item3.typeName,
						})
					}
				})
				material1.value[category] = list1;
				alignedMaterial1.value[category] = list1;
				material2.value[category] = list2;
				alignedMaterial2.value[category] = list2;
			}else{
				// 商品3比商品1物料种类少
				list1.forEach((item1, index) => {
					if(index + 1 <= list3.length){
						if (item1.typeName != list3[index].typeName) {
							list3.splice(index, 0, {
								amount: 0,
								isDiffer: false,
								type: item1.type,
								typeName: item1.typeName,
							})
						}
					}else{
						list3.push({
							amount: 0,
							isDiffer: false,
							type: item1.type,
							typeName: item1.typeName,
						})
					}
				})
				material3.value[category] = list3;
				alignedMaterial3.value[category] = list3;
			}
		})
	}
};
onMounted(() => {
	getTraceid();
  goodsCompare();
	productId.value = route.query.productId;
});
</script>

<style lang="less" scoped>
.goodscompare {
  width: 1920px;
  height: 949px;
  background: #ffffff;
  position: relative;
  .goodscompare-1 {
    width: 245px;
    height: 31px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 32px;
    color: #111111;
    position: absolute;
    top: 153px;
    left: 837px;
  }
  .goodscompareall {
    .goodscompare-2-7 {
      position: absolute;
      left: 310px;
      bottom: 33px;
    }
    .goodscompare-2-8 {
      width: 2px;
      height: 40px;
      background: #e7e7e7;
      position: absolute;
      left: 420px;
      bottom: 22px;
    }
    .goodscompare-2-9 {
      position: absolute;
      left: 452px;
      bottom: 33px;
      cursor: pointer;
    }
    .goodscompare-2-10 {
      position: absolute;
      left: 483px;
      bottom: 34px;
    }
  }
  .goodscompare-2 {
    width: 364px;
    height: 570px;
    position: absolute;
    top: 284px;
    left: 310px;
    .goodscompare-2-1 {
      width: 340px;
      height: 48px;
      border-radius: 24px;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      .goodscompare-2-2 {
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        position: absolute;
        left: 42px;
        top: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare-2-3 {
        position: absolute;
        left: 301px;
        top: 19px;
      }
    }
    /deep/ .el-select .el-input__wrapper {
      cursor: pointer;
      width: 200px;
      height: 48px;
      border-radius: 24px;
    }
    /deep/ .el-select .el-input__inner {
      cursor: pointer;
      margin-left: 20px;
    }
    /deep/.el-input__suffix-inner {
      pointer-events: all;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-right: 6px;
    }
    .goodscompare-2-4 {
      width: 300px;
      height: 300px;
      margin: 48px 0 0 20px;
    }
    .goodscompare-2-5 {
      width: 200px;
      height: 48px;
      margin: 30px 0 0 68px;
    }
    .goodscompare-2-6 {
      width: 200px;
      height: 48px;
      text-align: center;
      background: #ce1200;
      border-radius: 24px;
      margin: 32px 0 0 68px;
      line-height: 48px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .goodscompare-2-plus {
    border-radius: 30px;
    border: 1px solid #aaaaaa;
    width: 364px;
    height: 514px;
    position: absolute;
    top: 271px;
    left: 310px;
    overflow: hidden;
    /deep/ .el-select .el-input__wrapper {
      cursor: pointer;
      width: 200px;
      height: 48px;
      border-radius: 24px;
    }
    /deep/ .el-select .el-input__inner {
      cursor: pointer;
      margin-left: 20px;
    }
    /deep/.el-input__suffix-inner {
      pointer-events: all;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-right: 6px;
    }
    .goodscompare-2-1 {
      width: 340px;
      height: 48px;
      border-radius: 24px;
      cursor: pointer;
      background: #f2f2f2;
      border: 1px solid #d9d9d9;
      margin: 13px 0 0 13px;
      .goodscompare-2-2 {
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        position: absolute;
        left: 42px;
        top: 26px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare-2-3 {
        position: absolute;
        left: 301px;
        top: 32px;
      }
    }
    .goodscompare-2-4 {
      width: 230px;
      height: 230px;
      background: #f2f2f2;
      border-radius: 20px;
      margin: 64px 0 0 55px;
      .goodscompare-2-4-1 {
        margin: 80px 0 0 81px;
      }
    }
    .goodscompare-2-5 {
      width: 142px;
      height: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #999999;
      margin: 30px 0 0 99px;
    }
    .goodscompare-2-6 {
      width: 356px;
      height: 373px;
      margin-top: 29px;
      margin-left: 18px;
      overflow-y: scroll;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      padding-top: 3px;
      .goodscompare-2-7 {
        margin-bottom: 8px;
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        cursor: pointer;
        margin-right: 8px;
        position: relative;
        .hoverShadow ();
        .goodscompare-2-8 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-9 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
          text-align: center;
        }
        .goodscompare-2-10{
          width: 36px;
          height: 20px;
          background: #999999;
          border-radius: 5px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 11px;
          color: #FFFFFF;
          text-align: center;
          line-height: 20px;
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }
      .goodscompare-2-7:hover {
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #ce1200;
        cursor: pointer;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-8 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-9 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
        }
      }
    }
  }
  .goodscompare-3 {
    width: 364px;
    height: 570px;
    position: absolute;
    top: 284px;
    left: 778px;
    .goodscompare-2-6-plus{
      width: 200px;
      height: 48px;
      text-align: center;
      background: #ce1200;
      border-radius: 24px;
      margin: 32px 0 0 68px;
      line-height: 48px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      cursor: pointer;
    }
    .goodscompare-2-1 {
      width: 340px;
      height: 48px;
      cursor: pointer;
      border-radius: 24px;
      border: 1px solid #d9d9d9;
      .goodscompare-2-2 {
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        position: absolute;
        left: 42px;
        top: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare-2-3 {
        position: absolute;
        left: 301px;
        top: 19px;
      }
    }
    .goodscompare-2-4 {
      width: 230px;
      height: 230px;
      background: #f2f2f2;
      border-radius: 20px;
      margin: 64px 0 0 55px;
      .goodscompare-2-4-1 {
        margin: 80px 0 0 81px;
      }
    }
    .goodscompare-2-4-plus{
      width: 300px;
      height: 300px;
      margin: 48px 0 0 20px;
    }
    .goodscompare-2-5 {
      width: 142px;
      height: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #999999;
      margin: 30px 0 0 99px;
    }
    .goodscompare-2-5-plus {
      width: 200px;
      height: 48px;
      margin: 30px 0 0 68px;
    }
    /deep/ .el-select .el-input__wrapper {
      cursor: pointer;
      width: 200px;
      height: 48px;
      border-radius: 24px;
    }
    /deep/ .el-select .el-input__inner {
      cursor: pointer;
      margin-left: 20px;
    }
    /deep/.el-input__suffix-inner {
      pointer-events: all;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-right: 6px;
    }
  }
  .goodscompare-3-plus {
    border-radius: 30px;
    border: 1px solid #aaaaaa;
    width: 364px;
    height: 514px;
    position: absolute;
    top: 271px;
    left: 778px;
    overflow: hidden;
    .goodscompare-2-1 {
      width: 340px;
      height: 48px;
      border-radius: 24px;
      cursor: pointer;
      background: #f2f2f2;
      border: 1px solid #d9d9d9;
      margin: 13px 0 0 13px;
      .goodscompare-2-2 {
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        position: absolute;
        left: 42px;
        top: 26px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare-2-3 {
        position: absolute;
        left: 301px;
        top: 32px;
      }
    }
    .goodscompare-2-4 {
      width: 230px;
      height: 230px;
      background: #f2f2f2;
      border-radius: 20px;
      margin: 64px 0 0 55px;
      .goodscompare-2-4-1 {
        margin: 80px 0 0 81px;
      }
    }
    .goodscompare-2-5 {
      width: 142px;
      height: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #999999;
      margin: 30px 0 0 99px;
    }
    /deep/ .el-select .el-input__wrapper {
      cursor: pointer;
      width: 200px;
      height: 48px;
      border-radius: 24px;
    }
    /deep/ .el-select .el-input__inner {
      cursor: pointer;
      margin-left: 20px;
    }
    /deep/.el-input__suffix-inner {
      pointer-events: all;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-right: 6px;
    }
    .goodscompare-2-6 {
      width: 356px;
      height: 373px;
      margin-top: 29px;
      margin-left: 18px;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding-top: 3px;
      .goodscompare-2-7 {
        margin-bottom: 8px;
        position: relative;
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        cursor: pointer;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-8 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-9 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
          text-align: center;
        }
        .goodscompare-2-10{
          width: 36px;
          height: 20px;
          background: #999999;
          border-radius: 5px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 11px;
          color: #FFFFFF;
          text-align: center;
          line-height: 20px;
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }
      .goodscompare-2-7:hover {
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #ce1200;
        cursor: pointer;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-8 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-9 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
        }
      }
    }
  }
  .goodscompare-4 {
    width: 364px;
    height: 570px;
    position: absolute;
    top: 284px;
    left: 1270px;
    .goodscompare-2-1 {
      width: 340px;
      height: 48px;
      border-radius: 24px;
      cursor: pointer;
      border: 1px solid #d9d9d9;
      .goodscompare-2-2 {
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        position: absolute;
        left: 42px;
        top: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare-2-3 {
        position: absolute;
        left: 301px;
        top: 19px;
      }
    }
    .goodscompare-2-6-plus{
      width: 200px;
      height: 48px;
      text-align: center;
      background: #ce1200;
      border-radius: 24px;
      margin: 32px 0 0 68px;
      line-height: 48px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      cursor: pointer;
    }
    .goodscompare-2-4 {
      width: 230px;
      height: 230px;
      background: #f2f2f2;
      border-radius: 20px;
      margin: 64px 0 0 55px;
      .goodscompare-2-4-1 {
        margin: 80px 0 0 81px;
      }
    }
    .goodscompare-2-4-plus{
      width: 300px;
      height: 300px;
      margin: 48px 0 0 20px;
    }
    .goodscompare-2-5 {
      width: 142px;
      height: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #999999;
      margin: 30px 0 0 99px;
    }
    .goodscompare-2-5-plus {
      width: 200px;
      height: 48px;
      margin: 30px 0 0 68px;
    }
    /deep/ .el-select .el-input__wrapper {
      cursor: pointer;
      width: 200px;
      height: 48px;
      border-radius: 24px;
    }
    /deep/ .el-select .el-input__inner {
      cursor: pointer;
      margin-left: 20px;
    }
    /deep/.el-input__suffix-inner {
      pointer-events: all;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-right: 6px;
    }
  }
  .goodscompare-4-plus {
    border-radius: 30px;
    border: 1px solid #aaaaaa;
    width: 364px;
    height: 514px;
    position: absolute;
    top: 271px;
    left: 1270px;
    overflow: hidden;
    .goodscompare-2-1 {
      width: 340px;
      height: 48px;
      border-radius: 24px;
      cursor: pointer;
      background: #f2f2f2;
      border: 1px solid #d9d9d9;
      margin: 13px 0 0 13px;
      .goodscompare-2-2 {
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        position: absolute;
        left: 42px;
        top: 26px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare-2-3 {
        position: absolute;
        left: 301px;
        top: 32px;
      }
    }
    .goodscompare-2-4 {
      width: 230px;
      height: 230px;
      background: #f2f2f2;
      border-radius: 20px;
      margin: 64px 0 0 55px;
      .goodscompare-2-4-1 {
        margin: 80px 0 0 81px;
      }
    }
    .goodscompare-2-5 {
      width: 142px;
      height: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #999999;
      margin: 30px 0 0 99px;
    }
    .goodscompare-2-6 {
      width: 356px;
      height: 373px;
      margin-top: 29px;
      margin-left: 18px;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      padding-top: 3px;
      align-content: flex-start;
      .goodscompare-2-7 {
        position: relative;
        margin-bottom: 8px;
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        cursor: pointer;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-8 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-9 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
          text-align: center;
        }
        .goodscompare-2-10{
          width: 36px;
          height: 20px;
          background: #999999;
          border-radius: 5px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 11px;
          color: #FFFFFF;
          text-align: center;
          line-height: 20px;
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }
      .goodscompare-2-7:hover {
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #ce1200;
        cursor: pointer;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-8 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-9 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
        }
      }
    }
  }
}
.goodscompare2 {
  width: 1920px;
  height: 314px;
  background: #ffffff;
  position: fixed;
  .goodscompare2-1 {
    width: 340px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    position: absolute;
    left: 310px;
    top: 104px;
    .goodscompare2-2 {
      width: 242px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 17px;
      color: #333333;
      position: absolute;
      left: 42px;
      top: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .goodscompare2-3 {
      position: absolute;
      left: 301px;
      top: 19px;
    }
  }
  .goodscompare2-4 {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 310px;
    top: 168px;
  }
  .goodscompare2-5 {
    position: absolute;
    left: 401px;
    top: 168px;
    width: 230px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 17px;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .goodscompare2-6 {
    position: absolute;
    left: 401px;
    top: 200px;
    width: 150px;
    height: 36px;
    text-align: center;
    background: #ce1200;
    border-radius: 18px;
    line-height: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
  }
  .goodscompare2-7 {
    position: absolute;
    left: 310px;
    top: 266px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 20px;
    color: #333333;
  }
  .goodscompare2-8 {
    position: absolute;
    left: 420px;
    top: 261px;
    width: 2px;
    height: 40px;
    background: #e7e7e7;
  }
  .goodscompare2-9 {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 450px;
    top: 271px;
    cursor: pointer;
  }
  .goodscompare2-10 {
    position: absolute;
    left: 481px;
    top: 269px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 17px;
    color: #666666;
  }
  .goodscompare2-else {
    border-radius: 30px;
    border: 1px solid #aaaaaa;
    width: 364px;
    height: 514px;
    position: absolute;
    top: 90px;
    left: 298px;
    background: #ffffff;
    overflow: hidden;
    .goodscompare2-1 {
      width: 340px;
      height: 48px;
      border-radius: 24px;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      position: absolute;
      left: 12px;
      top: 13px;
      background: #f2f2f2;
      .goodscompare2-2 {
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        position: absolute;
        left: 42px;
        top: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare2-2-plus{
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #ce1200;
        position: absolute;
        left: 42px;
        top: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare2-3 {
        position: absolute;
        left: 301px;
        top: 19px;
      }
    }
    .goodscompare-2-1 {
      width: 356px;
      height: 437px;
      margin-top: 29px;
      margin-left: 18px;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      padding-top: 63px;
      align-content: flex-start;
      .goodscompare-2-2 {
        margin-bottom: 8px;
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        cursor: pointer;
        position: relative;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-3 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-4 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
        }
        .goodscompare-2-10{
          width: 36px;
          height: 20px;
          background: #999999;
          border-radius: 5px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 11px;
          color: #FFFFFF;
          text-align: center;
          line-height: 20px;
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }
      .goodscompare-2-2:hover {
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #ce1200;
        cursor: pointer;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-3 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-4 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
        }
      }
    }
  }
  .goodscompare2-11 {
    width: 340px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    position: absolute;
    left: 790px;
    top: 104px;
    .goodscompare2-12 {
      width: 242px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 17px;
      color: #333333;
      position: absolute;
      left: 42px;
      top: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .goodscompare2-13 {
      position: absolute;
      left: 301px;
      top: 19px;
    }
  }
  .goodscompare2-else1 {
    border-radius: 30px;
    border: 1px solid #aaaaaa;
    width: 364px;
    height: 514px;
    position: absolute;
    top: 90px;
    left: 778px;
    background: #ffffff;
    overflow: hidden;
    .goodscompare2-1 {
      width: 340px;
      height: 48px;
      border-radius: 24px;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      position: absolute;
      left: 12px;
      top: 13px;
      background: #f2f2f2;
      .goodscompare2-2 {
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        position: absolute;
        left: 42px;
        top: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare2-2-plus{
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #ce1200;
        position: absolute;
        left: 42px;
        top: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare2-3 {
        position: absolute;
        left: 301px;
        top: 19px;
      }
    }
    .goodscompare-2-1 {
      width: 356px;
      height: 437px;
      margin-top: 29px;
      margin-left: 18px;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      padding-top: 63px;
      align-content: flex-start;
      .goodscompare-2-2 {
        margin-bottom: 8px;
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        cursor: pointer;
        position: relative;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-3 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-4 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
        }
        .goodscompare-2-10{
          width: 36px;
          height: 20px;
          background: #999999;
          border-radius: 5px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 11px;
          color: #FFFFFF;
          text-align: center;
          line-height: 20px;
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }
      .goodscompare-2-2:hover {
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #ce1200;
        cursor: pointer;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-3 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-4 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
        }
      }
    }
  }
  .goodscompare2-14 {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 790px;
    top: 168px;
  }
  .goodscompare2-15 {
    position: absolute;
    left: 881px;
    top: 168px;
    width: 230px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 17px;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .goodscompare2-16 {
    position: absolute;
    left: 881px;
    top: 200px;
    width: 150px;
    height: 36px;
    text-align: center;
    background: #ce1200;
    border-radius: 18px;
    line-height: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
  }
  .goodscompare2-17 {
    width: 340px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    position: absolute;
    left: 1270px;
    top: 104px;
    .goodscompare2-18 {
      width: 242px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 17px;
      color: #333333;
      position: absolute;
      left: 42px;
      top: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .goodscompare2-19 {
      position: absolute;
      left: 301px;
      top: 19px;
    }
  }
  .goodscompare2-20 {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 1270px;
    top: 168px;
  }
  .goodscompare2-else2 {
    border-radius: 30px;
    border: 1px solid #aaaaaa;
    width: 364px;
    height: 514px;
    position: absolute;
    top: 90px;
    left: 1258px;
    background: #ffffff;
    overflow: hidden;
    .goodscompare2-1 {
      width: 340px;
      height: 48px;
      border-radius: 24px;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      position: absolute;
      left: 12px;
      top: 13px;
      background: #f2f2f2;
      .goodscompare2-2 {
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        position: absolute;
        left: 42px;
        top: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare2-2-plus{
        width: 242px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 17px;
        color: #ce1200;
        position: absolute;
        left: 42px;
        top: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goodscompare2-3 {
        position: absolute;
        left: 301px;
        top: 19px;
      }
    }
    .goodscompare-2-1 {
      width: 356px;
      height: 437px;
      margin-top: 29px;
      margin-left: 18px;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      padding-top: 63px;
      align-content: flex-start;
      .goodscompare-2-2 {
        margin-bottom: 8px;
        width: 104px;
        position: relative;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        cursor: pointer;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-3 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-4 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
        }
        .goodscompare-2-10{
          width: 36px;
          height: 20px;
          background: #999999;
          border-radius: 5px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 11px;
          color: #FFFFFF;
          text-align: center;
          line-height: 20px;
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }
      .goodscompare-2-2:hover {
        width: 104px;
        height: 144px;
        border-radius: 10px;
        border: 1px solid #ce1200;
        cursor: pointer;
        margin-right: 8px;
        .hoverShadow ();
        .goodscompare-2-3 {
          width: 84px;
          height: 84px;
          margin: 11px 0 0 10px;
        }
        .goodscompare-2-4 {
          width: 83px;
          height: 34px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 3px 0 0 10px;
        }
      }
    }
  }
  .goodscompare2-21 {
    position: absolute;
    left: 1361px;
    top: 168px;
    width: 230px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 17px;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .goodscompare2-22 {
    position: absolute;
    left: 1361px;
    top: 200px;
    width: 150px;
    height: 36px;
    text-align: center;
    background: #ce1200;
    border-radius: 18px;
    line-height: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
  }
}
.compare {
  width: 1920px;
	padding: 419px 0 80px 300px;
	.compare1{
		display: flex;
		padding: 0px 0 48px 0px;
	}
  .compare-1{
	  width: 480px;
    .container-top-left {
    .title-text {
      height: 20px;
      display: flex;
      margin-left: 13px;
      .text-span {
        margin-top: 3px;
        width: 3px;
        height: 14px;
        background: #ce1200;
      }
      .container-top-l {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        font-family: Microsoft YaHei;
        margin-left: 6px;
        margin-top: -5px;
      }
    }
    .container-top-2 {
      margin: 23px 0 0 10px;
      .container-title {
        display: flex;
        .container-top-3 {
          margin-bottom: 6px;
          width: 339px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          margin-right: 138px;
        }
      }
      .container-content {
        display: flex;
        .container-top-4 {
          width: 340px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin-right: 38px;
        }
        .container-top-5{
          width: 12px;
          height: 9px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin-right: 86px;
        }
        .container-top-active {
          width: 340px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #ce1200;
          margin-right: 38px;
        }
      }
    }
  }
  }
  .compare-2{
    width: 480px;
    .container-top-left {
    .title-text {
      height: 20px;
      display: flex;
      margin-left: 13px;
      .text-span {
        margin-top: 3px;
        width: 3px;
        height: 14px;
        background: #ce1200;
      }
      .container-top-l {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        font-family: Microsoft YaHei;
        margin-left: 6px;
        margin-top: -5px;
      }
    }
    .container-top-2 {
      margin: 23px 0 0 10px;
      .container-title {
        display: flex;
        .container-top-3 {
          margin-bottom: 6px;
          width: 339px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          margin-right: 138px;
        }
      }
      .container-content {
        display: flex;
        .container-top-4 {
          width: 340px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin-right: 38px;
        }
        .container-top-5{
          width: 12px;
          height: 9px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin-right: 86px;
        }
        .container-top-4-plus {
          width: 340px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #ce1200;
          margin-right: 38px;
        }
        .container-top-5-plus{
          width: 12px;
          height: 9px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #ce1200;
          margin-right: 86px;
        }
        .container-top-active {
          width: 340px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #ce1200;
          margin-right: 38px;
        }
      }
    }
  }
  }
  .compare-3{
	  width: 480px;
    .container-top-left {
    .title-text {
      height: 20px;
      display: flex;
      margin-left: 13px;
      .text-span {
        margin-top: 3px;
        width: 3px;
        height: 14px;
        background: #ce1200;
      }
      .container-top-l {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        font-family: Microsoft YaHei;
        margin-left: 6px;
        margin-top: -5px;
      }
    }
    .container-top-2 {
      margin: 23px 0 0 10px;
      .container-title {
        display: flex;
        .container-top-3 {
          margin-bottom: 6px;
          width: 339px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          margin-right: 138px;
        }
      }
      .container-content {
        display: flex;
        .container-top-4 {
          width: 340px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin-right: 38px;
        }
        .container-top-5{
          width: 12px;
          height: 9px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin-right: 86px;
        }
        .container-top-4-plus {
          width: 340px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #ce1200;
          margin-right: 38px;
        }
        .container-top-5-plus{
          width: 12px;
          height: 9px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #ce1200;
          margin-right: 86px;
        }
        .container-top-active {
          width: 340px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #ce1200;
          margin-right: 38px;
        }
      }
    }
  }
  }
}
</style>
